// export type State = { classes: object };
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/store.enums';

@Module
export default class AuthModule extends VuexModule {
  accessToken = '';
  refreshToken = '';
  isLoggedIn = false;

  get getToken(): string {
    return this.accessToken;
  }

  get getIsLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  @Mutation
  [Mutations.SET_ACCESS_TOKEN_M](payload) {
    this.accessToken = payload;
  }

  @Action
  [Actions.SET_ACCESS_TOKEN](payload) {
    this.context.commit(Mutations.SET_ACCESS_TOKEN_M, payload);
  }

  @Mutation
  [Mutations.SET_REFRESH_TOKEN_M](payload) {
    this.refreshToken = payload;
  }

  @Action
  [Actions.SET_REFRESH_TOKEN](payload) {
    this.context.commit(Mutations.SET_REFRESH_TOKEN_M, payload);
  }

  @Action
  [Actions.DESTROY_ACCESS_TOKEN]() {
    this.context.commit(Mutations.SET_ACCESS_TOKEN_M, null);
  }

  @Action
  [Actions.DESTROY_REFRESH_TOKEN]() {
    this.context.commit(Mutations.SET_REFRESH_TOKEN_M, null);
  }

  @Mutation
  [Mutations.SET_IS_LOGGED_IN_M](payload) {
    this.isLoggedIn = payload;
  }

  @Action
  [Actions.SET_IS_LOGGED_IN]() {
    this.context.commit(Mutations.SET_IS_LOGGED_IN_M, true);
  }

  @Action
  [Actions.SET_IS_LOGGED_OUT]() {
    this.context.commit(Mutations.SET_IS_LOGGED_IN_M, false);
  }
}
