
import { defineComponent, onMounted, ref } from "vue";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";

export default defineComponent({
  name: "Sidebar3",
  components: {
    Dropdown3
  },
  setup() {
    const list = ref([
      {
        title: "Blue Donut",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-17.jpg"
      },
      {
        title: "Lovely Hearts",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-10.jpg"
      },
      {
        title: "Hands & Yellow",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-1.jpg"
      },
      {
        title: "Cup & Green",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-9.jpg"
      },
      {
        title: "Bose QC 35 II",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-4.jpg"
      }
    ]);
    const series = ref([74]);
    const chartOptions = ref({
      chart: {
        height: 350,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "78%"
          },
          track: {
            background: "#00A3FF",
            strokeWidth: "100%"
          }
        }
      },
      colors: ["#F1416C"],
      labels: ["Progress"]
    });

    onMounted(() => {
      ScrollComponent.reinitialization();
    });

    return {
      list,
      series,
      chartOptions
    };
  }
});
