import { App } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { AxiosResponse } from 'axios'

/**
 * @description service to call HTTP request via Axios
 */
class DadataService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    // console.log('DADATA init ::')
    DadataService.vueInstance = app
    DadataService.vueInstance.use(VueAxios, axios)
    DadataService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_DADATA_BASE_URL
    DadataService.vueInstance.axios.defaults.headers.common['Accept'] = 'application/json'
    DadataService.vueInstance.axios.defaults.headers.common['Content-Type'] = 'application/json'
    DadataService.vueInstance.axios.defaults.headers.common['Authorization'] = `Token ${process.env.VUE_APP_DADATA_KEY}`
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    DadataService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_DADATA_BASE_URL
    DadataService.vueInstance.axios.defaults.headers.common['Authorization'] = `Token ${process.env.VUE_APP_DADATA_KEY}`
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params): Promise<AxiosResponse> {
    return DadataService.vueInstance.axios.post(`${resource}`, params)
  }
}

export default DadataService
