import { App } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store/index'
import { Actions } from '@/store/enums/store.enums'
import AuthService from '@/core/services/AuthService'
import { AxiosResponse, AxiosRequestConfig } from 'axios'
import LocalStorageService from '@/core/services/LocalStorageService'

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    const token = LocalStorageService.getToken('accessToken')
    store.dispatch(Actions.SET_ACCESS_TOKEN, token)
    if (token) {
      store.dispatch(Actions.SET_IS_LOGGED_IN)
    } else {
      store.dispatch(Actions.SET_IS_LOGGED_OUT)
    }
    ApiService.vueInstance = app
    ApiService.vueInstance.use(VueAxios, axios)
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_HOST_API
    ApiService.vueInstance.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    ApiService.vueInstance.axios.defaults.headers.common['X-Dev-Auth-Token'] = process.env.VUE_APP_X_DEV_AUTH_TOKEN
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common['Authorization'] = `Bearer ${store.state['AuthModule'].accessToken}`
    ApiService.vueInstance.axios.defaults.headers.common['X-Dev-Auth-Token'] = process.env.VUE_APP_X_DEV_AUTH_TOKEN
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_HOST_API
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setRefreshHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common['Authorization'] = `${LocalStorageService.getToken('accessToken')}`
    ApiService.vueInstance.axios.defaults.headers.common['X-Dev-Auth-Token'] = process.env.VUE_APP_X_DEV_AUTH_TOKEN
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_HOST_API
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeaderMultipart(): void {
    ApiService.vueInstance.axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch(async error => {
      if (error.response && error.response.status) {
        if (error.response.status === 401) {
          if (await AuthService.refreshToken()) {
            if (AuthService.isTokenExpired() === 200) {
              return ApiService.vueInstance.axios.get(resource, params).catch(error => {
                if (error.response && error.response.status) {
                  throw(`Ошибка ${error.response.status}`)
                } else {
                  throw('Неопределённая ошибка')
                }
              })
            } else {
              throw('Истёк срок действия токена')
            }
          } else {
            throw('Проблема с обновлением рефреш токена')
          }
        } else if (error.response.status === 422) {
          throw('Не корректные данные')
        } else if (error.response.status === 500) {
          throw('Серверная ошибка')
        } else {
          throw(`Ошибка ${error.response.status}`)
        }
      } else {
        throw('Неопределённая ошибка')
      }
    })
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`).catch(async error => {
      if (error.response && error.response.status) {
        if (error.response.status === 401) {
          if (await AuthService.refreshToken()) {
            if (AuthService.isTokenExpired() === 200) {
              return ApiService.vueInstance.axios.get(`${resource}`).catch(error => {
                if (error.response && error.response.status) {
                  throw(`Ошибка ${error.response.status}`)
                } else {
                  throw('Неопределённая ошибка')
                }
              })
            } else {
              throw('Истёк срок действия токена')
            }
          } else {
            throw('Проблема с обновлением рефреш токена')
          }
        } else if (error.response.status === 422) {
          throw('Не корректные данные')
        } else if (error.response.status === 500) {
          throw('Серверная ошибка')
        } else {
          throw(`Ошибка ${error.response.status}`)
        }
      } else {
        throw('Неопределённая ошибка')
      }
    })
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params).catch(async error => {
      if (error.response && error.response.status) {
        if (error.response.status === 401) {
          if (await AuthService.refreshToken()) {
            if (AuthService.isTokenExpired() === 200) {
              return ApiService.vueInstance.axios.post(`${resource}`, params).catch(error => {
                if (error.response && error.response.status) {
                  throw(`Ошибка ${error.response.status}`)
                } else {
                  throw('Неопределённая ошибка')
                }
              })
            } else {
              throw('Истёк срок действия токена')
            }
          } else {
            throw('Проблема с обновлением рефреш токена')
          }
        } else if (error.response.status === 422) {
          // console.log('> error.response', error.response.data.errors)
          // console.log('> keys', Object.keys(error.response.data.errors))
          // Object.entries(error.response.data.errors).map(item => {
          //   console.log(item)
          // })
          throw(error)
        } else if (error.response.status === 500) {
          throw('Серверная ошибка')
        } else {
          throw(`Ошибка ${error.response.status}`)
        }
      } else {
        throw('Неопределённая ошибка')
      }
    })
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params)
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params)
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }
}

export default ApiService
