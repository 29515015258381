/**
 * @description get the token value by its key
 * @param tokenKey: string
 */
export const getToken = (tokenKey: string): string | null => {
  return window.localStorage.getItem(tokenKey);
};

/**
 * @description save the token value by its key
 * @param tokenKey: string
 * @param tokenValue: string
 */
export const saveToken = (tokenKey: string, tokenValue: string): void => {
  window.localStorage.setItem(tokenKey, tokenValue);
};

/**
 * @description destroy the token value by its key
 * @param tokenKey: string
 */
export const destroyToken = (tokenKey: string): void => {
  window.localStorage.removeItem(tokenKey);
};

export default { getToken, saveToken, destroyToken };
