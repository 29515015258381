import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/store.enums';
import ApiService from '@/core/services/ApiService';
import { ElMessage } from 'element-plus';

@Module
export default class RubricsModule extends VuexModule {
  rubricsList = [];
  rubricsTree = [];

  @Mutation
  [Mutations.SET_RUBRICS_LIST_MUTATION](payload) {
    this.rubricsList = payload;
  }

  @Mutation
  [Mutations.SET_RUBRICS_TREE_MUTATION](payload) {
    this.rubricsTree = payload;
  }

  /**
   * Get flat list of rubrics.
   * @returns array
   */
  get getRubricsList() {
    return this.context.state['rubricsList'];
  }

  /**
   * Get tree of rubrics.
   * @returns array
   */
  get getRubricsTree() {
    return this.context.state['rubricsTree'];
  }

  /**
   * Convert rubrics the list to the tree.
   * @returns array
   */
  @Action
  async [Actions.SET_RUBRICS_TREE](array) {
    // const array = this.context.state['rubricsList'];

    // Convert the flat list to the tree structure for the cascade component.
    // Detect 1st level items.
    let d1Array = array.filter(item => item.depth_level === 1);

    // Fill 1st level items with 2nd level items.
    d1Array = d1Array.map(d1item => {
      const d1itemCopy = { ...d1item };
      d1itemCopy['children'] = [];
      d1itemCopy.value = d1itemCopy.code;
      d1itemCopy.label = d1itemCopy.name;
      d1itemCopy.disabled = true;
      array.forEach(d2item => {
        const d2itemCopy = { ...d2item };
        d2itemCopy['children'] = [];
        d2itemCopy.value = d2itemCopy.code;
        d2itemCopy.label = d2itemCopy.name;
        if (d1item.id === d2item.parent_id) {
          array.forEach(d3item => {
            const d3itemCopy = { ...d3item };
            d3itemCopy['children'] = [];
            d3itemCopy.value = d3itemCopy.code;
            d3itemCopy.label = d3itemCopy.name;
            if (d2item.id === d3item.parent_id) {
              array.forEach(d4item => {
                const d4itemCopy = { ...d4item };
                d4itemCopy.value = d4itemCopy.code;
                d4itemCopy.label = d4itemCopy.name;
                if (d3item.id === d4item.parent_id) {
                  d3itemCopy.children.push(d4itemCopy);
                }
              });
              d2itemCopy.children.push(d3itemCopy);
            }
          });
          d1itemCopy.children.push(d2itemCopy);
        }
      });
      return d1itemCopy;
    });

    if (d1Array && d1Array.length) {
      this.context.commit(Mutations.SET_RUBRICS_TREE_MUTATION, d1Array);
    }

    return d1Array;
  }

  /**
   * API request for rubrics.
   * @returns array
   */
  @Action
  async [Actions.GET_RUBRICS_LIST](params) {
    const res = await ApiService.query('/rubrics', { params })
      .then(response => {
        // console.log('> GET_RUBRICS_LIST', response.data.data)
        return response.data.data;
      })
      .catch(error => {
        ElMessage.warning(
          'Что-то не так с запросом причин отклонения новостей'
        );
        console.error('GET_RUBRICS_LIST :: error ::', error);
        return false;
      });
    return res
  }
}
