
import { defineComponent, onMounted, ref } from "vue";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";

export default defineComponent({
  name: "Sidebar",
  components: {
    Dropdown3
  },
  setup() {
    onMounted(() => {
      ScrollComponent.createInstances('[data-kt-scroll="true"]');
    });

    const series1 = ref([
      {
        name: "Profit",
        data: [40, 30, 25, 40, 50, 30]
      }
    ]);

    const series2 = ref([
      {
        name: "Profit",
        data: [30, 30, 25, 45, 30, 40]
      }
    ]);

    const series3 = ref([
      {
        name: "Profit",
        data: [25, 30, 40, 30, 35, 30]
      }
    ]);

    const series4 = ref([
      {
        name: "Profit",
        data: [25, 30, 35, 40, 50, 30]
      }
    ]);

    const series5 = ref([
      {
        name: "Profit",
        data: [40, 20, 50, 50, 55, 45]
      }
    ]);

    const chartOptions = ref({
      chart: {
        fontFamily: "inherit",
        type: "bar",
        height: 250,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded"
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        crosshairs: {
          show: false
        },
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: "#823FD9",
            fontSize: "12px"
          }
        }
      },
      yaxis: {
        crosshairs: {
          show: false
        },
        labels: {
          style: {
            colors: "#823FD9",
            fontSize: "12px"
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none"
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        style: {
          fontSize: "12px"
        },
        y: {
          formatter: function(val) {
            return "$" + val + "k";
          }
        }
      },
      colors: ["#661DC5"],
      grid: {
        borderColor: "#661DC5",
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    });

    return {
      chartOptions,
      series1,
      series2,
      series3,
      series4,
      series5
    };
  }
});
