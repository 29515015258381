import ApiService from '@/core/services/ApiService'
import {
  Actions,
  Mutations
} from '@/store/enums/store.enums'
import {
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators'
import { ElMessage } from 'element-plus'

@Module
export default class AdminModule extends VuexModule {
  adminData = null;

  get getAdminData(): string {
    return this.adminData;
  }

  @Mutation
  [Mutations.SET_ADMIN_DATA_MUTATION](payload) {
    // console.log('> run SET_ADMIN_DATA_MUTATION', payload)
    this.adminData = payload;
  }

  @Action
  [Actions.SET_ADMIN_DATA](payload) {
    // console.log('> run SET_ADMIN_DATA', payload)
    this.context.commit(Mutations.SET_ADMIN_DATA_MUTATION, payload);
  }

  /**
   * Request the admin data.
   */
  @Action
  async [Actions.REQUEST_ADMIN_DATA]() {
    ApiService.setHeader()

    return await ApiService.get('/admin/users/profile')
      .then(response => {
        this.context.commit(Mutations.SET_ADMIN_DATA_MUTATION, response.data)
        return true
      })
        .catch(() => {
          console.error('> run requestTheProfile >> Error')
          return false
        })
  }
}
