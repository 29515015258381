import jwt from 'jsonwebtoken'
import store from '@/store/index'
import { Actions } from '@/store/enums/store.enums'
import ApiService from '@/core/services/ApiService'
import LocalStorageService from '@/core/services/LocalStorageService'
import { ElMessage } from 'element-plus'


const removeTokens = () => {
  // console.log('==========================')
  store.dispatch(Actions.SET_IS_LOGGED_OUT)
  store.dispatch(Actions.DESTROY_ACCESS_TOKEN)
  store.dispatch(Actions.DESTROY_REFRESH_TOKEN)
  LocalStorageService.destroyToken('accessToken')
  LocalStorageService.destroyToken('refreshToken')
  // console.log('Tokens has been removed ::', store.state['AuthModule'].isLoggedIn)
  // console.log('==========================')
}


/**
 * @description refresh the access token.
 */
const refreshToken = async () => {
  ApiService.setRefreshHeader()

  // console.log('==========================')
  // console.log('= accessToken', LocalStorageService.getToken('accessToken'))
  const refreshTokenResult = await ApiService.post('/admin/auth/refresh', {
    'refresh_token': LocalStorageService.getToken('refreshToken')
  })
    .then(response => {
      console.log('> Refresh >> SUCCESS')
      store.dispatch(Actions.SET_IS_LOGGED_IN)
      store.dispatch(Actions.SET_ACCESS_TOKEN, response.data.access_token)
      store.dispatch(Actions.SET_REFRESH_TOKEN, response.data.refresh_token)
      LocalStorageService.saveToken('accessToken', response.data.access_token)
      LocalStorageService.saveToken('refreshToken', response.data.refresh_token)
      // console.log( 'LocalStorageService.getToken ::', LocalStorageService.getToken('accessToken'))
      ApiService.setHeader()
      return true
    })
      .catch(() => {
        console.log('> Refresh >> Error')
        removeTokens()
        return false
      })
  // console.log('refreshToken ::')
  // console.log('==========================')
  return refreshTokenResult
}


const detectTokenStatus = (
  currentDate,
  expiredDateAccess
) => {
  // 200, 300, 400,
  let detectTokenStatusResult = 400
  // console.log(
  //   new Date(currentDate),
  //   new Date(expiredDateAccess),
  // )
  if (currentDate < expiredDateAccess) {
    detectTokenStatusResult = 200
  } else if (
    currentDate > expiredDateAccess
  ) {
    detectTokenStatusResult = 300
  } else {
    detectTokenStatusResult = 400
  }
  return detectTokenStatusResult
}


/**
 * @description detect expirtaion time of the auth token.
 */
const isTokenExpired = () => {
  let tokenStatus = 400
  const token = LocalStorageService.getToken('accessToken')
  if (token) {
    const currentDate = new Date().getTime()

    // Keys for testing authorization
    // const expiredDateAccess = (jwt.decode(LocalStorageService.getToken('accessToken')).exp * 1000) - (58 * 60 * 1000)
    const expiredDateAccess = jwt.decode(LocalStorageService.getToken('accessToken')).exp * 1000

    // console.log('> expiredDateAccess ::', expiredDateAccess)
    // console.log('> expiredDateAccess+ ::', expiredDateAccess - 58 * 60 * 1000)

    // console.log('> currentDate ::', new Date(currentDate).toLocaleString("ru-RU"))
    // console.log('>  dateAccess ::', new Date(expiredDateAccess).toLocaleString("ru-RU"))

    tokenStatus = detectTokenStatus(
      currentDate,
      expiredDateAccess
    )
  } else {
    tokenStatus = 400
  }
  return tokenStatus
}


/**
 * @description login by email and password.
 */
const logIn = async (params): Promise<boolean> => {
  // console.log('Login call ::', params)
  ApiService.setHeader()

  return await ApiService.post('/admin/auth/login', params)
    .then(response => {
      store.dispatch(Actions.SET_IS_LOGGED_IN)
      store.dispatch(Actions.SET_ACCESS_TOKEN, response.data.access_token)
      store.dispatch(Actions.SET_REFRESH_TOKEN, response.data.refresh_token)
      LocalStorageService.saveToken('accessToken', response.data.access_token)
      LocalStorageService.saveToken('refreshToken', response.data.refresh_token)
      ApiService.setHeader()
      return true
    })
      .catch(error => {
        ElMessage.warning(error)
        removeTokens()
        return false
      })
}


export default { isTokenExpired, refreshToken, removeTokens, logIn }
