import { createApp } from 'vue'
import App from '@/App.vue'

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from '@/router/index'
import store from '@/store/index'
import ElementPlus from 'element-plus'

// imports for app initialization
import ApiService from '@/core/services/ApiService'
import DadataService from '@/core/services/DadataService'
import LayoutService from '@/core/services/LayoutService'
import { initInlineSvg } from '@/core/plugins/inline-svg'
import { initVeeValidate } from '@/core/plugins/vee-validate'
import { createGlobalComponents } from '@/core/components/components'
import VueNumberFormat from 'vue-number-format'

import '@/core/plugins/bootstrap'
import '@/core/plugins/keenthemes'
import '@/core/plugins/prismjs'

// import VueTelInput from 'vue3-tel-input';
// import 'vue-tel-input/dist/vue-tel-input.css';

const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(VueNumberFormat)

createGlobalComponents(app)
initInlineSvg(app)
initVeeValidate()

DadataService.init(app)
ApiService.init(app)
LayoutService.init()

app.mount('#app')
