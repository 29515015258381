
import { defineComponent, onMounted, ref } from "vue";
import useShopFilter from "@/core/helpers/shop";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";

export default defineComponent({
  name: "Sidebar2",
  components: {},
  setup() {
    const {
      displayFilterForm,
      showProductAddForm,
      showProductFilterForm,
      initSlider,
      newProductFormSubmit
    } = useShopFilter();

    const sliderRef = ref<HTMLElement | null>(null);

    onMounted(() => {
      if (sliderRef.value) {
        sliderRef.value.innerHTML = "";
      }

      initSlider();

      ScrollComponent.reinitialization();
    });

    return {
      displayFilterForm,
      showProductAddForm,
      showProductFilterForm,
      newProductFormSubmit
    };
  }
});
