import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store/index';
import AuthService from '@/core/services/AuthService';
import { Actions } from '@/store/enums/store.enums'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboards',
    component: () => import('@/view/layout/Layout.vue'),
    // component: () => import("@/view/pages/dashboards/Light.vue"),
    children: [
      {
        path: '/dashboards',
        name: 'dashboards',
        component: () => import('@/view/pages/dashboards/Start.vue'),
        meta: {
          title: 'Дашборд',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
      },
      {
        path: '/news',
        name: 'news',
        redirect: '/news/list',
        meta: {
          name: 'Новости',
          title: 'Новости',
          desc: 'Список новостей',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import('@/view/pages/common/news/News.vue'),
        children: [
          {
            path: '/news/list',
            name: 'news-list',
            meta: {
              name: 'Новости',
              title: 'Новости',
              desc: 'Список новостей',
              createNewPage: true,
              asideEnabled: true,
              asidePrimaryDisabled: true,
            },
            component: () => import('@/view/pages/common/news/NewsList.vue'),
          },
          {
            path: '/news/create',
            name: 'news-create',
            meta: {
              title: 'Создать новость',
              name: 'Создать новость',
              buttonBack: true,
              asideEnabled: true,
              asidePrimaryDisabled: true,
            },
            component: () => import('@/view/pages/common/news/Create.vue'),
          },
          {
            path: '/news/edit/:id',
            name: 'news-edit',
            meta: {
              title: 'Редактировать новость',
              name: 'Редактировать новость',
              buttonBack: true,
              asideEnabled: true,
              asidePrimaryDisabled: true,
            },
            component: () => import('@/view/pages/common/news/Edit.vue'),
          },
        ]
      },
      {
        path: "/ads",
        name: "ads",
        redirect: '/ads/list',
        meta: {
          title: 'Объявления',
          name: 'Объявления',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/common/ads/ads.vue"),
        children: [
          {
            path: '/ads/list',
            name: 'ads-list',
            meta: {
              name: 'Объявления',
              title: 'Объявления',
              desc: 'Список объявлений',
              createNewPage: true,
              asideEnabled: true,
              asidePrimaryDisabled: true,
            },
            component: () => import('@/view/pages/common/ads/AdsList.vue'),
          },
          {
            path: '/ads/create',
            name: 'ads-create',
            meta: {
              title: 'Создать объявление',
              name: 'Создать объявление',
              buttonBack: true,
              asideEnabled: true,
              asidePrimaryDisabled: true,
            },
            component: () => import('@/view/pages/common/ads/Create.vue'),
          },
          {
            path: '/ads/edit/:id',
            name: 'ads-edit',
            meta: {
              title: 'Редактировать объявление',
              name: 'Редактировать объявление',
              buttonBack: true,
              asideEnabled: true,
              asidePrimaryDisabled: true,
            },
            component: () => import('@/view/pages/common/ads/Edit.vue'),
          },
        ]
      },
      {
        path: "/users",
        name: "users",
        redirect: '/users/list',
        meta: {
          title: 'Пользователи',
          name: 'Пользователи',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/common/users/Users.vue"),
        children: [
          {
            path: '/users/list',
            name: 'users-list',
            meta: {
              name: 'Пользователи',
              title: 'Пользователи',
              desc: 'Список пользователей',
              createNewPage: true,
              asideEnabled: true,
              asidePrimaryDisabled: true,
            },
            component: () => import('@/view/pages/common/users/UsersList.vue'),
          },
          {
            path: '/users/create',
            name: 'users-create',
            meta: {
              title: 'Создать пользователя',
              name: 'Создать пользователя',
              buttonBack: true,
              asideEnabled: true,
              asidePrimaryDisabled: true,
            },
            component: () => import('@/view/pages/common/users/Create.vue'),
          },
          {
            path: '/users/edit/:id',
            name: 'users-edit',
            meta: {
              title: 'Редактировать пользователя',
              name: 'Редактировать пользователя',
              buttonBack: true,
              asideEnabled: true,
              asidePrimaryDisabled: true,
            },
            component: () => import('@/view/pages/common/users/Edit.vue'),
          },
        ]
      },
      {
        path: '/tickets',
        name: 'tickets-page',
        meta: {
          title: 'Тикеты',
          name: 'Тикеты',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import('@/view/pages/common/Tickets.vue')
      },
      {
        path: "/companies",
        name: "companies",
        meta: {
          title: 'Компании',
          name: 'Компании',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/common/Companies.vue")
      },
      {
        path: "/statistics",
        name: "statistics",
        meta: {
          title: 'Статистика',
          name: 'Статистика',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/common/Statistics.vue")
      },
      {
        path: "/newsletter",
        name: "newsletter",
        meta: {
          title: 'Email рассылки',
          name: 'Email рассылки',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/services/Newsletter.vue")
      },
      {
        path: "/newsletter-users",
        name: "newsletter-users",
        meta: {
          title: 'Рассылки участникам',
          name: 'Рассылки участникам',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/services/NewsletterUsers.vue")
      },
      {
        path: "/caching-testing",
        name: "caching-testing",
        meta: {
          title: 'Тестирование кэширования',
          name: 'Тестирование кэширования',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/services/CachingTesting.vue")
      },
      {
        path: "/acts-printing",
        name: "acts-printing",
        meta: {
          title: 'Печать актов',
          name: 'Печать актов',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/billing/ActsPrinting.vue")
      },
      {
        path: "/cities",
        name: "cities",
        meta: {
          title: 'Города',
          name: 'Города',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/catalog/Cities.vue")
      },
      {
        path: "/articles-sources",
        name: "articles-sources",
        meta: {
          title: 'Источники статей',
          name: 'Источники статей',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/catalog/ArticlesSources.vue")
      },
      {
        path: "/video",
        name: "video",
        meta: {
          title: 'Видео',
          name: 'Видео',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/media/Video.vue")
      },
      {
        path: "/logs",
        name: "logs",
        meta: {
          title: 'Логи',
          name: 'Логи',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/settings/Logs.vue")
      },
      {
        path: "/category-setting",
        name: "category-setting",
        meta: {
          title: 'Настройка рубрик',
          name: 'Настройка рубрик',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/settings/CategorySetting.vue")
      },
      {
        path: "/settings",
        name: "my-settings",
        meta: {
          title: 'Настройки',
          name: 'Настройки',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/settings/CategorySetting.vue")
      },
      {
        path: "/profile",
        name: "my-profile",
        meta: {
          title: 'Мой профиль',
          name: 'Мой профиль',
          sidebar: 'user',
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/settings/CategorySetting.vue")
      },
      {
        path: "/documentation",
        name: "documentation",
        meta: {
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import("@/view/pages/resources/Documentation.vue"),
        children: [
          {
            path: "build",
            name: "build",
            component: () =>
              import(
                "@/view/pages/resources/documentation/get-started/Build.vue"
              )
          },
          {
            path: "doc-overview",
            name: "doc-overview",
            component: () =>
              import(
                "@/view/pages/resources/documentation/get-started/Overview.vue"
              )
          },
          {
            path: "updates",
            name: "updates",
            component: () =>
              import(
                "@/view/pages/resources/documentation/get-started/Updates.vue"
              )
          },
          {
            path: "changelog",
            name: "changelog",
            component: () => import("@/view/pages/resources/Changelog.vue")
          },
          {
            path: "utilities",
            name: "utilities",
            meta: {
              desc: "extended utility classes"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Utilities.vue")
          },
          {
            path: "helpers/flex-layouts",
            name: "flex-layouts",
            meta: {
              desc: "extended flex layout classes"
            },
            component: () =>
              import(
                "@/view/pages/resources/documentation/base/helpers/FlexLayouts.vue"
              )
          },
          {
            path: "helpers/text",
            name: "text",
            meta: {
              desc: "extended text classes"
            },
            component: () =>
              import(
                "@/view/pages/resources/documentation/base/helpers/Text.vue"
              )
          },
          {
            path: "helpers/background",
            name: "backkground",
            meta: {
              desc: "extended background classes"
            },
            component: () =>
              import(
                "@/view/pages/resources/documentation/base/helpers/Background.vue"
              )
          },
          {
            path: "helpers/borders",
            name: "borders",
            meta: {
              desc: "extended borders classes"
            },
            component: () =>
              import(
                "@/view/pages/resources/documentation/base/helpers/Borders.vue"
              )
          },
          {
            path: "forms",
            name: "forms",
            meta: {
              desc: "forms elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Forms.vue")
          },
          {
            path: "buttons",
            name: "buttons",
            meta: {
              desc: "buttons elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Buttons.vue")
          },
          {
            path: "indicator",
            name: "indicator",
            meta: {
              desc: "indicator element"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Indicator.vue")
          },
          {
            path: "rotate",
            name: "rotate",
            meta: {
              desc: "Rotate element"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Rotate.vue")
          },
          {
            path: "tables",
            name: "tables",
            meta: {
              desc: "extended bootstrap tables"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Tables.vue")
          },
          {
            path: "cards",
            name: "cards",
            meta: {
              desc: "card elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Cards.vue")
          },
          {
            path: "symbol",
            name: "symbol",
            meta: {
              desc: "symbol elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Symbol.vue")
          },
          {
            path: "badges",
            name: "badges",
            meta: {
              desc: "badge elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Badges.vue")
          },
          {
            path: "pulse",
            name: "pulse",
            meta: {
              desc: "pulse elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Pulse.vue")
          },
          {
            path: "bullets",
            name: "bullets",
            meta: {
              desc: "bullets elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Bullets.vue")
          },
          {
            path: "accordion",
            name: "accordion",
            meta: {
              desc: "accordion elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Accordion.vue")
          },
          {
            path: "carousel",
            name: "carousel",
            meta: {
              desc: "carousel elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Carousel.vue")
          },
          {
            path: "overlay",
            name: "overlay",
            meta: {
              desc: "overlay elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Overlay.vue")
          },
          {
            path: "separator",
            name: "separator",
            meta: {
              desc: "separator elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Separator.vue")
          },
          {
            path: "tabs",
            name: "tabs",
            meta: {
              desc: "tabs elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Tabs.vue")
          },
          {
            path: "breadcrumb",
            name: "breadcrumb",
            meta: {
              desc: "breadcrumb elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Breadcrumb.vue")
          },
          {
            path: "modal",
            name: "modal",
            meta: {
              desc: "modal elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Modal.vue")
          },
          {
            path: "pagination",
            name: "pagination",
            meta: {
              desc: "pagination elements"
            },
            component: () =>
              import("@/view/pages/resources/documentation/base/Pagination.vue")
          },
          {
            path: "vue-select",
            name: "vue-select",
            meta: {
              desc: "Vue multiselect"
            },
            component: () =>
              import("@/view/pages/resources/documentation/forms/VueSelect.vue")
          },
          {
            path: "vee-validate",
            name: "vee-validate",
            meta: {
              desc: "Vee validate"
            },
            component: () =>
              import(
                "@/view/pages/resources/documentation/forms/VeeValidate.vue"
              )
          },
          {
            path: "element-ui",
            name: "element-ui",
            component: () =>
              import(
                "@/view/pages/resources/documentation/element-ui/ElementUI.vue"
              ),
            children: [
              {
                path: "basic/layout",
                name: "layout",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/basic/Layout.vue"
                  )
              },
              {
                path: "basic/layout-container",
                name: "layout-container",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/basic/LayoutContainer.vue"
                  )
              },
              {
                path: "basic/icon",
                name: "icon",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/basic/Icon.vue"
                  )
              },
              {
                path: "basic/button",
                name: "button",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/basic/Button.vue"
                  )
              },
              {
                path: "basic/link",
                name: "link",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/basic/Link.vue"
                  )
              },
              {
                path: "basic/space",
                name: "space",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/basic/Space.vue"
                  )
              },
              {
                path: "form/radio",
                name: "radio",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Radio.vue"
                  )
              },
              {
                path: "form/checkbox",
                name: "checkbox",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Checkbox.vue"
                  )
              },
              {
                path: "form/input",
                name: "input",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Input.vue"
                  )
              },
              {
                path: "form/input-number",
                name: "input-number",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/InputNumber.vue"
                  )
              },
              {
                path: "form/select",
                name: "select",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Select.vue"
                  )
              },
              {
                path: "form/cascader",
                name: "cascader",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Cascader.vue"
                  )
              },
              {
                path: "form/switch",
                name: "switch",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Switch.vue"
                  )
              },
              {
                path: "form/slider",
                name: "slider",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Slider.vue"
                  )
              },
              {
                path: "form/time-picker",
                name: "time-picker",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/TimePicker.vue"
                  )
              },
              {
                path: "form/time-select",
                name: "time-select",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/TimeSelect.vue"
                  )
              },
              {
                path: "form/date-picker",
                name: "date-picker",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/DatePicker.vue"
                  )
              },
              {
                path: "form/date-time-picker",
                name: "date-time-picker",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/DateTimePicker.vue"
                  )
              },
              {
                path: "form/upload",
                name: "upload",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Upload.vue"
                  )
              },
              {
                path: "form/rate",
                name: "rate",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Rate.vue"
                  )
              },
              {
                path: "form/color-picker",
                name: "color-picker",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/ColorPicker.vue"
                  )
              },
              {
                path: "form/transfer",
                name: "transfer",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Transfer.vue"
                  )
              },
              {
                path: "form/form",
                name: "form",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/form/Form.vue"
                  )
              },
              {
                path: "data/table",
                name: "table",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/data/Table.vue"
                  )
              },
              {
                path: "data/tag",
                name: "tag",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/data/Tag.vue"
                  )
              },
              {
                path: "data/progress",
                name: "progress",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/data/Progress.vue"
                  )
              },
              {
                path: "data/tree",
                name: "tree",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/data/Tree.vue"
                  )
              },
              {
                path: "data/pagination",
                name: "pagination",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/data/Pagination.vue"
                  )
              },
              {
                path: "data/badge",
                name: "badge",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/data/Badge.vue"
                  )
              },
              {
                path: "data/skeleton",
                name: "skeleton",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/data/Skeleton.vue"
                  )
              },
              {
                path: "data/empty",
                name: "empty",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/data/Empty.vue"
                  )
              },
              {
                path: "notice/alert",
                name: "alert",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/notice/Alert.vue"
                  )
              },
              {
                path: "notice/loading",
                name: "loading",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/notice/Loading.vue"
                  )
              },
              {
                path: "notice/message",
                name: "message",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/notice/Message.vue"
                  )
              },
              {
                path: "notice/message-box",
                name: "message-box",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/notice/MessageBox.vue"
                  )
              },
              {
                path: "notice/notification",
                name: "notification",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/notice/Notification.vue"
                  )
              },
              {
                path: "navigation/affix",
                name: "affix",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/navigation/Affix.vue"
                  )
              },
              {
                path: "navigation/nav-menu",
                name: "nav-menu",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/navigation/NavMenu.vue"
                  )
              },
              {
                path: "navigation/tabs",
                name: "tabs",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/navigation/Tabs.vue"
                  )
              },
              {
                path: "navigation/breadcrumb",
                name: "breadcrumb",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/navigation/Breadcrumb.vue"
                  )
              },
              {
                path: "navigation/page-header",
                name: "page-header",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/navigation/PageHeader.vue"
                  )
              },
              {
                path: "navigation/dropdown",
                name: "dropdown",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/navigation/Dropdown.vue"
                  )
              },
              {
                path: "navigation/steps",
                name: "steps",
                component: () =>
                  import(
                    "@/view/pages/resources/documentation/element-ui/navigation/Steps.vue"
                  )
              }
            ]
          },
          {
            path: "icons/duotone",
            name: "duotone",
            meta: {
              desc: "duotone svg icons"
            },
            component: () =>
              import("@/view/pages/resources/documentation/general/Duotone.vue")
          },
          {
            path: "icons/bootstrap-icons",
            name: "bootstrap-icons",
            meta: {
              desc: "free, high quality, open source icon library"
            },
            component: () =>
              import(
                "@/view/pages/resources/documentation/general/BootstrapIcons.vue"
              )
          },
          {
            path: "icons/font-awesome",
            name: "font-awesome",
            meta: {
              desc: "awesome font icons"
            },
            component: () =>
              import(
                "@/view/pages/resources/documentation/general/FontAwesome.vue"
              )
          },
          {
            path: "icons/line-awesome",
            name: "line-awesome",
            meta: {
              desc: "line font icons"
            },
            component: () =>
              import(
                "@/view/pages/resources/documentation/general/LineAwesome.vue"
              )
          },
        ],
      },
      {
        path: '/pricing',
        name: 'pricing',
        meta: {
          asideEnabled: true,
          asidePrimaryDisabled: true,
        },
        component: () => import('@/view/pages/general/Pricing.vue'),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  },
  {
    path: '/login',
    name: 'login-page',
    component: () => import('@/view/pages/auth/Login.vue'),
    meta: {
      name: 'Войти',
    },
  },
  {
    path: '/logout',
    name: 'logout-page',
    component: () => import('@/view/pages/auth/Login.vue'),
    meta: {
      name: 'Выйти',
    },
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: 'error-404',
    meta: {
      type: '404',
    },
    component: () => import('@/view/pages/general/Error.vue'),
  },
  {
    path: '/403',
    name: 'error-403',
    meta: {
      type: '403',
    },
    component: () => import('@/view/pages/general/Error.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})


const logout = (to, next) => {
  if (to !== 'login-page') {
    next({
      name: 'login-page',
    })
  } else {
    next()
  }
}


const meta = to => {
  document.title = `${to.meta.title}`
}


const guard = async (to, from, next) => {
  const isLoggedIn = store.getters.getIsLoggedIn
  const isTokenStatus = AuthService.isTokenExpired()

  if (isLoggedIn) {
    if (isTokenStatus === 200) {
      next()
    } else if (isTokenStatus === 300) {
      if (await AuthService.refreshToken()) {
        if (AuthService.isTokenExpired() === 200) {
          store.dispatch(Actions.SET_IS_LOGGED_IN)
          next()
        } else {
          logout(to.name, next)
        }
      } else {
        logout(to.name, next)
      }
    } else {
      AuthService.removeTokens()
      logout(to.name, next)
    }
  } else {
    logout(to.name, next)
  }
}


router.beforeEach(async (to, from, next) => {
  meta(to)
  await guard(to, from, next)
})


export default router;
