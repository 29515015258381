import { Module, VuexModule, Action } from 'vuex-module-decorators';
import { Actions } from '@/store/enums/store.enums';
import ApiService from '@/core/services/ApiService';
import { ElMessage } from 'element-plus';

@Module
export default class PhotosModule extends VuexModule {

  /**
   * API request for image uploading.
   * @returns array
   */
  @Action
  async [Actions.UPLOAD_PHOTO](file) {
    console.log('> UPLOAD_PHOTO', file)
    const formData = new FormData();
    formData.append('file', file.raw);
    console.log('> formData', formData);
    ApiService.setHeader();

    const res = await ApiService.post('/admin/file/upload/good', formData)
      .then(response => {
        // console.log('> UPLOAD_PHOTO >> response', response.data.data.id);
        return response.data.data.id;
      })
        .catch(() => {
          ElMessage.error('ApiService file upload error');
          console.log('> UPLOAD_PHOTO >> error');
          return null;
        })
      return res;
  }
}
