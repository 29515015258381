import { Module, VuexModule, Action } from 'vuex-module-decorators'
import { Actions } from '@/store/enums/store.enums'
import ApiService from '@/core/services/ApiService'
import { ElMessage } from 'element-plus'

@Module
export default class CategoriesModule extends VuexModule {
  // Get categories list.
  @Action
  async [Actions.GET_CATEGORIES_LIST]() {
    return await ApiService.get('/news/categories')
      .then(response => {
        return response.data
      })
      .catch(error => {
        ElMessage.warning('Что-то не так с запросом категорий новостей')
        console.error('GET_CATEGORIES_LIST :: error ::', error)
        return false
      })
  }
}
