import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import AuthModule from '@/store/modules/AuthModule';
import RubricsModule from '@/store/modules/RubricsModule';
import CategoriesModule from '@/store/modules/CategoriesModule';
import AdminModule from '@/store/modules/AdminModule';
import PhotosModule from '@/store/modules/PhotosModule';

config.rawError = true;

const store = createStore({
  state: {},
  modules: {
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    AuthModule,
    RubricsModule,
    CategoriesModule,
    AdminModule,
    PhotosModule,
  },
});

export default store;
