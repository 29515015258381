enum Actions {
  // action types
  ADD_BODY_CLASSNAMES = 'addBodyClassNames',
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  SET_ACCESS_TOKEN = 'setAccessToken',
  SET_REFRESH_TOKEN = 'setRefreshToken',
  DESTROY_ACCESS_TOKEN = 'destroyAccessToken',
  DESTROY_REFRESH_TOKEN = 'destroyRefreshToken',
  SET_IS_LOGGED_IN = 'setIsLoggedIn',
  SET_IS_LOGGED_OUT = 'setIsLoggedOut',
  GET_RUBRICS_LIST = 'setRubricsList',
  UPLOAD_PHOTO = 'uploadPhoto',
  GET_CATEGORIES_LIST = 'getCategoriesList',
  SET_RUBRICS_TREE = 'setRubricsTree',
  SET_ADMIN_DATA = 'setAdminData',
  REQUEST_ADMIN_DATA = 'requestAdminData',
  SET_SIDEBAR_STATE = 'SetSidebarState',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_ACCESS_TOKEN_M = 'setAccessTokenM',
  SET_REFRESH_TOKEN_M = 'setRefreshTokenM',
  SET_IS_LOGGED_IN_M = 'setIsLoggedInM',
  SET_RUBRICS_LIST_MUTATION = 'setRubricsListMutation',
  SET_RUBRICS_TREE_MUTATION = 'setRubricsTreeMutation',
  SET_ADMIN_DATA_MUTATION = 'setAdminDataMutation',
  MUTATE_SIDEBAR_STATE = 'MutateSidebarState',
}

export { Actions, Mutations };
