
import { defineComponent, onMounted } from "vue"
import { useStore } from "vuex"
import { Actions, Mutations } from "@/store/enums/store.enums"
import store from '@/store/index'

export default defineComponent({
  name: 'app',
  setup() {
    const store = useStore()
    const isLoggedIn = store.getters.getIsLoggedIn

    onMounted(async () => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/layout.config.ts)
       */
      if (isLoggedIn) {
        await store.dispatch(Actions.REQUEST_ADMIN_DATA)
        store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG)
      }
    });
  },
});
